.login-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background: hsla(291, 35%, 15%, 1);

  background: linear-gradient(
    90deg,
    hsla(291, 35%, 15%, 1) 0%,
    hsla(290, 26%, 27%, 1) 20%,
    hsla(289, 19%, 35%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(291, 35%, 15%, 1) 0%,
    hsla(290, 26%, 27%, 1) 20%,
    hsla(289, 19%, 35%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(291, 35%, 15%, 1) 0%,
    hsla(290, 26%, 27%, 1) 20%,
    hsla(289, 19%, 35%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#2E1832", endColorstr="#523458", GradientType=1 );
}

.logo-container {
  display: flex;
  width: 100%;
  margin: 20px;
  padding-left: 25px;
  height: clamp(30px, 10%, 50px);
}

.login-form-container {
  display: flex;
  padding: 50px;
  position: relative;
  border-radius: 5px;
  text-align: center;
  transition: all 0.2s;
  box-sizing: border-box;
  flex-flow: column wrap;
  background-color: white;
  width: clamp(350px, 100%, 450px);
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

.page-footer {
  width: 100%;
  height: 50px;
  display: flex;
  gap: 5px;
  color: white;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}

.page-footer img {
  max-width: 75px;
}
